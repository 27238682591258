<template>
  <div>
    <div class="container my-5" id="tcToPdf">
      <div class="row text-center">
        <div class="col-md-10 mx-auto">
          <h1>Terms and Conditions</h1>

          <h4 style="color: #83c550">Information about us:</h4>
          <p>
            www.amchara.com is a site licensed to Amchara Limited. in the
            UK and Amchara Limited in Malta.
          </p>

          <p>
            We offer residential health
            retreats, online consultations and functional medicine tests
            and operate the Amchara brand under licence.
          </p>

          <p>
            Amchara Limited in the UK is registered in England and Wales
            under company number 14310697 and have our main trading address
            is Bindon House, Langford Budville, Wellington, Somerset TA21 0RU.
          </p>

          <p>
            Amchara Limited in Malta is registered under company number number
            C68523 and have our main trading address as Villagg Ta'Sbejha, Mongur
            St, Gozo, Malta.
          </p>

          <h4 style="color: #83c550">Bookings:</h4>
          <p>
            For residential retreat bookings over the phone please contact us by
            phone or email to check availability on your required dates or for
            any enquiries regarding your booking.
          </p>

          <p>
            For online consultations bookings and to order functional medicine
            tests please contact us via email or telephone.
          </p>

          <p>
            Bookings for the UK retreat are contracted with Amchara Limited
            a UK incorporated company. Bookings with the Malta retreat are
            contracted with Amchara Ltd a Maltese incorporated company.
          </p>

          <p>
            A deposit of £500 must be paid to secure your booking. The remainder
            balance must be received 8 weeks prior to the retreat start date.
            Bookings made within 8 weeks of the retreat start date must be paid
            in full.
          </p>

          <p>
            For bookings online only available dates are bookable, full payment
            is taken immediately at the time of booking.
          </p>

          <p>
            For online consultations and functional medicine tests full payment
            is taken at the time of booking.
          </p>

          <p>
            When you book with us you understand and accept these terms and
            conditions
          </p>

          <h4 style="color: #83c550">Payments:</h4>
          <p>
            Payments are made through Stripe by debit or credit card either over
            the phone, online or through our secure payment portal.
          </p>
          <p>
            Cards accepted are Visa, Mastercard, Maestro & Amex, credit or
            debit.
          </p>

          <h4 style="color: #83c550">Accommodation:</h4>
          <p>
            In the UK all bedrooms are en-suite. Bedrooms maybe single occupancy
            or may be shared by a maximum of 2 people who are known to each
            other, for example a couple or people of the same household.
          </p>

          <h4 style="color: #83c550">Cancellation & Refunds:</h4>
          <p>
            If you wish to cancel your retreat then you must confirm this in
            writing.
          </p>

          <p>
            Once your booking is confirmed, you have 24 hours to cancel your
            booking and receive a full refund.
          </p>

          <p>After this the following conditions will apply,</p>

          <p>
            For any cancellations 90 days before the start day of your retreat,
            you will receive a full refund minus a 10% admin charge.
          </p>

          <p>
            If you cancel between 57-89 days prior to the start day of your
            retreat a 50% refund will be provided.
          </p>

          <p>If you cancel between 0-56 days then no refund is provided.</p>

          <p>
            Should you leave the retreat early for any reason no refund will be
            provided.
          </p>

          <p>
            In the event of an ‘Act of God’ flooding, fire, war, earthquakes,
            pandemics or/and any restrictions on travel placed by Governments
            then no refund will be provided
          </p>

          <p>
            Amchara is an alcohol free zone and we have a zero tolerance policy
            to clients drinking alcohol whilst in residence at Amchara. Anybody
            found with alcohol or deemed to be under the influence of alcohol
            (or illegal narcotics) at Amchara will be required to leave
            immediately with no refund.
          </p>

          <p>
            Amchara operates a zero-tolerance policy towards abuse of staff or
            other clients. Any clients abusing any staff members or negatively
            impacting on any other clients during their stay with Amchara will
            be asked to leave immediately with no refund.
          </p>

          <p>
            If you wish to cancel your consultation or order of your functional
            medicine tests this must be done before the consultation or/and
            before we order the functional medicine test. Once we have ordered
            the test or/and booked the consultation there is no refund
          </p>

          <h4 style="color: #83c550">Medical Disclaimer:</h4>
          <p>
            Please read our Contra-Indications to Juice Fasting & Colonics
            before booking your retreat. You must inform Amchara of any
            pre-existing medical conditions or contra-indications at the time of
            booking. You must also disclose all pre-existing medical conditions
            and contra-indications on the Confidential Health Questionnaire
            which will be sent to you in your information pack after booking
            your retreat.
          </p>

          <p>
            Amchara management may feel it is necessary to contact you to
            discuss any part of the detox program including participation in
            classes and treatments prior to your arrival.
          </p>

          <p>
            If you are undergoing any form of Physiotherapy treatment,
            Osteopathy or Chiropractic treatment we recommend you seek clearance
            from your practitioner regarding participation in any of the classes
            or treatments.
          </p>

          <p>
            We recommend that you seek advice from your doctor if you have any
            concerns regarding any part of our detox program such as Juice
            Fasting, Colonics, classes or treatments.
          </p>

          <p>
            If you are on any form of medication you should seek advice from
            your doctor as to whether our detox program is contra-indicated and
            to ensure our program is safe for you to participate in. You are
            fully responsible for your own health during your stay with us
            including participation in any classes or treatments.
          </p>

          <p>
            Amchara cannot be held responsible for any health issues that arise
            as a result of any possible contra-indications that have not been
            disclosed prior to arrival on the retreat.
          </p>

          <p>
            All the information on this website is based on our own research and
            knowledge. The information provided by us on our website should not
            be construed as medical advice and we do not accept any
            responsibility for any consequence that occurs from the usage of
            this site or its services.
          </p>

          <p>
            Amchara do not claim to diagnose or cure any existing medical
            treatment. However many clients do attend Amchara with medical and
            health conditions and their treatments and experience at Amchara
            helps them to self-heal from certain conditions.
          </p>

          <p>
            Many people attend detox centres to deal with physical symptoms such
            as, arthritis, candida, IBS, diabetes, asthma, fibromyalgia,
            digestive issues, fatigue, migraines, headaches, skin problems
            obesity, addictions, compulsive eating, eating disorders,
            haemorrhoids, high cholesterol, adrenal fatigue, insomnia,
            detoxification, depression, anxiety, severe stress, low self esteem,
            low self confidence, ME/CFS, remission, parasites – fibroids and
            other health conditions.
          </p>

          <h4 style="color: #83c550">Protecting Your Privacy:</h4>
          <p>
            Your personal information and data will never be sold or passed onto
            any third party though your private health records may be shared
            with appropriate therapists, nurses or doctors when we deem it
            either necessary or appropriate for your benefit and for best
            practice.
          </p>

          <h4 style="color: #83c550">Travel Insurance/Health Insurance:</h4>
          <p>
            We recommend that you purchase travel insurance in case you need to
            cancel your booking due to circumstances beyond your control such as
            sickness, or death of a family member, or changes in travel
            arrangements made by yourself or a travel company, or any other
            circumstance that may require you to cancel your retreat.
          </p>

          <p>
            N.B. Amchara reserves the right to change details of the
            programme/schedule or change therapists or any other details should
            circumstances necessitate it.
          </p>

          <p>
            <a href="https://stripe.com/gb" target="_blank">Find out more about payments via Stripe here.</a>
          </p>
        </div>
      </div>
    </div>

    <div class="container my-5">
      <div class="row text-center">
        <div class="col">
          <!--<a href="/terms/print" class="btn btn-success" target="_blank">
            <i class="fa fa-download me-1"></i>Download as PDF
          </a>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).title,
      content: this.$store.seo_metas.find(
        (i) => i.slug == this.$route.path.replace("/", "")
      ).description,
      // titleTemplate: null,
    };
  },
};
</script>

<style>
</style>